import { mapActions } from 'vuex';

export default {
  destroyed() {
    this.clearState();
  },

  methods: {
    ...mapActions({
      clearState: 'editableList/clearState',
    }),

    onCreate(route) {
      const params = {};

      params.previosRouteName = this.$route.name;

      if (this.$route.query?.filter) {
        params.previosRouteQueryFilter = JSON.parse(this.$route.query.filter);
      }

      if (this.$route.query?.table) {
        params.previosRouteQuerySort = JSON.parse(this.$route.query.table);
      }

      this.$router.push({
        name: route,
        params,
      });
    },

    onEdit(route, id) {
      const params = { id };

      params.previosRouteName = this.$route.name;

      if (this.$route.query?.filter) {
        params.previosRouteQueryFilter = JSON.parse(this.$route.query.filter);
      }

      if (this.$route.query?.table) {
        params.previosRouteQuerySort = JSON.parse(this.$route.query.table);
      }

      this.$router.push({ name: route, params });
    },
  },
};
