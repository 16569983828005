<template>
  <div class="input__wrapper">
    <label
      v-if="label"
      class="input__label"
    >{{ label }}</label>
    <input
      class="input"
      ref="input"
      :value="value"
      :type="inputType"
      :placeholder="placeholder"
      :name="name"
      :disabled="disabled"
      :required="required"
      :readOnly="readOnly"
      :autofocus="autofocus"
      :autocomplete="autocomplete"
      :tabindex="inputTabindex"
      :step="step"
      :min="min"
      :max="max"
      :inputTabindex="inputTabindex"
      v-mask="vMask"
      :class="{ 'input--interactive': isInterectiveInput }"
      @input="onInput"
      @keyup.enter="onEnter"
    />

    <template v-if="isInterectiveInput">
      <button
        class="input__button"
        type="button"
        :tabindex="buttonTabindex"
        @click="onInterectiveButton"
      >
        <sm-icon
          class="input__icon"
          :name="icon"
        />
      </button>
    </template>
  </div>
</template>

<script>
import SmIcon from '@/components/common/SmIcon.vue';
import { mask } from 'vue-the-mask';

export default {
  name: 'SmInput',

  components: {
    SmIcon,
  },

  directives: {
    mask: (el, binding) => {
      if (!binding.value) return;
      mask(el, binding);
    }
  },

  props: {
    label: {
      type: String,
      default: '',
    },

    value: {
      type: [String, Number, Boolean],
      default: '',
    },

    type: {
      type: String,
      default: 'text',
    },

    placeholder: {
      type: String,
      placeholder: '',
    },

    name: {
      type: String,
      name: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      default: false,
    },

    readOnly: {
      type: Boolean,
    },

    autofocus: {
      type: Boolean,
    },

    autocomplete: {
      type: String,
      default: 'off',
    },

    step: {
      type: Number,
    },

    min: {
      type: Number,
    },

    max: {
      type: Number,
    },

    buttonTabindex: {
      type: Number,
      default: -1,
    },

    inputTabindex: {
      type: Number,
    },

    mask: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
      showPassword: true,
      interectiveTypes: ['search', 'password'],
      unsupportedTypes: [
        'button',
        'submit',
        'reset',
        'radio',
        'checkbox',
        'hidden',
        'image',
      ],
    };
  },

  computed: {
    inputType() {
      if (this.unsupportedTypes.find((type) => type === this.type)) {
        console.error(
          `Type ${this.type} unsupported. Find ${this.type} component in '@/src/components/common/forms' or create ${this.type} component.`
        );
        return;
      }

      if (this.type === 'password') {
        return !this.showPassword ? 'text' : this.type;
      }

      return this.type;
    },

    isInterectiveInput() {
      return !!this.interectiveTypes.find((type) => type === this.type);
    },

    isSearch() {
      return this.type === 'search';
    },

    isPassword() {
      return this.type === 'password';
    },

    icon() {
      if (this.isSearch) {
        return this.value.length ? 'times' : 'search';
      }

      if (this.isPassword) {
        return this.showPassword ? 'eye' : 'eye-off';
      }

      return '';
    },

    vMask() {
      return this.mask ? this.mask : false;
    },
  },

  methods: {
    onInput(event) {
      this.$emit('input', event.target.value);
    },

    onInterectiveButton() {
      this.$refs.input.focus();
      this.$emit('enter');

      if (this.isPassword) {
        this.showPassword = !this.showPassword;
        return;
      }
      if (this.value.length) {
        this.$emit('input', '');
      }
    },
  },
};
</script>

<style lang="scss">
.input__wrapper {
  position: relative;

  width: 100%;
}

.input__label {
  display: inline-block;
  margin-bottom: 3px;

  color: var(--gray);
}

.input {
  width: 100%;
  height: 36px;
  padding: 7px 12px;

  font-size: 16px;
  line-height: 1;

  border: 1px solid var(--gray);
  border-radius: 10px;

  background-color: var(--white);

  outline: none;

  &::placeholder,
  &::-webkit-input-placeholder {
    color: var(--gray);
  }

  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  &:focus {
    border: 1px solid var(--blue);
  }
}

.input--interactive {
  padding-right: 26px;
}

.input__button {
  position: absolute;
  right: 6px;
  top: 8px;
  z-index: 2;

  font-size: 0;

  cursor: pointer;
}

.input__icon {
  fill: var(--gray);
}

.input:focus+.input__button .input__icon {
  fill: var(--blue);
}

.input:disabled {
  background-color: rgba(var(--rgb-gray), 0.2);

  // cursor: not-allowed;
}
</style>
